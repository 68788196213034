/******************************************************************

Stylesheet: Mixins & Constants Stylesheet

This is where you can take advantage of Sass' great features:
Mixins & Constants.

******************************************************************/

/*********************
TOOLS
*********************/

// http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
.image-replacement {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}


/*********************
COLORS
*********************/


/*********************
TYPOGRAPHY
*********************/
/*	@font-face {
    	font-family: 'Font Name';
    	src: url('assets/fonts/font-name.eot');
    	src: url('assets/fonts/font-name.eot?#iefix') format('embedded-opentype'),
             url('assets/fonts/font-name.woff') format('woff'),
             url('assets/fonts/font-name.ttf') format('truetype'),
             url('assets/fonts/font-name.svg#font-name') format('svg');
    	font-weight: normal;
    	font-style: normal;
	}
*/

//Use the best ampersand - http://simplebits.com/notebook/2008/08/14/ampersands-2/
span.amp {
  font-family: Baskerville,'Goudy Old Style',Palatino,'Book Antiqua',serif !important;
  font-style: italic;
}

/*********************
CSS3 GRADIENTS.
*********************/

/* @include css-gradient(#dfdfdf,#f8f8f8); */
@mixin css-gradient($from: #dfdfdf, $to: #f8f8f8) {
	background-color: $to;
	background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
	background-image: -webkit-linear-gradient(top, $from, $to);
	background-image: -moz-linear-gradient(top, $from, $to);
	background-image: -o-linear-gradient(top, $from, $to);
	background-image: linear-gradient(to bottom, $from, $to);
}


/// Convert angle
/// @author Chris Eppstein
/// @param {Number} $value - Value to convert
/// @param {String} $unit - Unit to convert to
/// @return {Number} Converted angle
@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
  @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
    @return $value
             / nth($conversion-factors, index($convertable-units, unit($value)))
             * nth($conversion-factors, index($convertable-units, $unit));
  }
  
  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/// Test if `$value` is an angle
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));
  
  @return $is-direction or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }
  
  $conversion-map: ( 
    to top          : bottom,
    to top right    : bottom left,
    to right top    : left bottom,
    to right        : left,
    to bottom right : top left,
    to right bottom : left top,
    to bottom       : top,
    to bottom left  : top right,
    to left bottom  : right top,
    to left         : right,
    to left top     : right bottom,
    to top left     : bottom right
  );
    
  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }
  
  @return 90deg - convert-angle($value, 'deg');
}

/// Mixin printing a linear-gradient 
/// as well as a plain color fallback 
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {String | List | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }
  
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}



/*********************
TYPOGRAPHY
*********************/

@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

//Use the best ampersand - http://simplebits.com/notebook/2008/08/14/ampersands-2/
span.amp {
  font-family: Baskerville,'Goudy Old Style',Palatino,'Book Antiqua',serif !important;
  font-style: italic;
}

/*********************
Border Radius.
*********************/
@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

// @mixin border-radius($radius) {
//  -webkit-border-radius: $radius;
//  border-radius: $radius;
//  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
// }

// Single side border-radius

@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
   -webkit-border-top-left-radius: $radius;
   border-top-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
     -webkit-border-top-right-radius: $radius;
     border-top-right-radius: $radius;
     background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
   -webkit-border-bottom-left-radius: $radius;
   border-bottom-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
     -webkit-border-top-left-radius: $radius;
     border-top-left-radius: $radius;
     background-clip: padding-box;
}

//CSS Transitions 

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}
@mixin opacity($opacity){
	-webkit-opacity: $opacity;
	-moz-opacity:    $opacity;
	opacity:         $opacity;	
}

@mixin box-shadow($left: 1px, $top: 2px, $blur: 3px, $spread: 0px, $color: rgba(0,0,0, 0.4), $inset: false) {
	@if $inset {
	-webkit-box-shadow:inset $left $top $blur $spread $color $inset;
	-moz-box-shadow:inset $left $top $blur $spread $color $inset;
	box-shadow:inset $left $top $blur $spread $color $inset;
	} @else {
	-webkit-box-shadow: $left $top $blur $spread $color;
	-moz-box-shadow: $left $top $blur $spread $color;
	box-shadow: $left $top $blur $spread $color;
	}
}

/// Text Shadow
/// @param {Size} $x [2px] - X
/// @param {Size} $y [2px] - Y
/// @param {Size} $blur [2px] - Blur
/// @param {Color} $color [rgba(0,0,0,.4)] - Color

@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4)) {
    text-shadow: $x $y $blur $color;
}

