@import "../_settings.scss";
// Import foundation.scss from vendor files
@import "../../../../node_modules/foundation-sites/scss/foundation";
.swiper-plugin-content-section {
	.swiper-container{
		overflow: hidden;
	}
	
	&.slider-to-expand{
		  .swiper-container{
			  @media all and (max-width: 768px){
				  .swiper-wrapper{
					  transform: none !important;
					  flex-direction: row;
					  flex-wrap: wrap;
					  .swiper-slide{
						  &:hover, 
						  &:focus{
							 .inner-slide{
								 .slide-bg-img-container{
									&:before{
										 background: rgba(0,0,0,.15);
									}
								 }
								.slider-content.tabletExpand{
									max-height: 100%;
									overflow: hidden;
								} 
							 }
						  }
						  .inner-slide{
							  min-height: 250px;
							.slider-title{
								padding-top: 160px;
								.expandTabletBtn {
									display: block !important;
									.showMe{
										display: block;
									}
									.hideMe{
										display: none;
									}
								}
							}  
							.slide-bg-img-container{
								height: 250px;
								top: 0;
								position: absolute;
								width: 100%;
								img{
									height: 100%;
									width: 100%;
									object-fit: cover;
								}
							}
							.slider-title{
								position: relative;
								padding-left: 2em; 
								padding-right: 2em;
								color: white;
								a.expandTabletBtn{
									span{
										color: white;
									}
								}
							}
							.slider-content.tabletExpand{
								max-height: 100%;
								overflow: hidden;
								.expandrTabletContent{
									max-height: 0;
									transition: max-height 0.35s ease-in-out;
									color:var(--body-font-color,#828282);
									h3{ color:var(--body-font-color,#828282);}
									&.expanded{
										max-height: 1000px;
									}
								}
							}
						  }
					  }
				}
			  }
			  @media all and (max-width: 768px){
				.swiper-controls{
					display: none;
				}	  
			}
			  .swiper-wrapper{
				  .swiper-slide{
					  .inner-slide{
						  .slider-title{
							  .expandTabletBtn{
								  display: none;
							  }
						  }
					  }
				  }
			  }
		  }
		  
		  .swiper-container.content-slider-synced{
			.swiper-wrapper{
				.swiper-slide{
					&.slide-has-bg-img{
						.inner-slide{
							@media all and (min-width: 768px){
								.slide-bg-img-container{
									display: none;
								}
							}
						}
					}
				}
			}  
		  }
		  
	  }
	  
	  .synced-img-slider{
		  .swiper-wrapper{
			  transform: none !important;
			  .swiper-slide{
				  opacity: 0;
				  transition: opacity 0.35s ease-in-out;
				  &.swiper-slide-active{
					  opacity: 1;
				  }
			  }
		  }
	  }
}

.swiper-plugin-image-section{
	.swiper-container{
		overflow: hidden;
	}
}

.synced-img-slider-has-expands{
	@include breakpoint(medium down){
		display: none;
	}
	
}

.synced-controls-has-expands{
	@include breakpoint(medium down){
		display: none;
	}
}